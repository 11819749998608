import ArticleList from '../../views/config/list/article'
import sceneList from '../../views/config/list/scene'
import keyword from '../../views/config/list/keyword'
import role from '../../views/config/list/role'
import city from '../../views/config/list/city'
import hot from '../../views/config/list/hot'
import typeConfig from '../../views/config/type'
const tabs= [
    {
      index: "0",
      name: "文章列表",
      path: "/config/articleList",
    
      component:ArticleList,
    },
    // {
    //     index:"45",
    //     name:"热门列表",
    //     component:hot,
    //     path:"/config/hot"
    //   },
    {
        index: "3",
        name: "景点列表",
        path: "/config/sceneList",
        
        component: sceneList,
       
      },
      {
        index: "12",
        name: "城市列表",
        path: "/config/cityList",
        component:city
      },
      // {
      //   index: "5",
      //   name: "关键词",
      //   path: "/config/keyword",
      //   component: keyword,
       
      // },
      
      {
        index:"44",
        name:"类型设置",
        component:typeConfig,
        path:"/config/type",
        children:[
          {
            path:"/config/type/:typeIdentity",
            component:typeConfig,
          }
        ]
      },
   
    //   {
    //     index: "6",
    //     name: "角色",
    //     path: "/config/role",
    //     component: role,
    //   },
    // {
    //   index: "1",
    //   name: "Trip 类型列表",
    //   path: "/config/tripTypeList",
     
    //   component: "tripTypeList",
    //   typeIdentity: "tripType",
    // },
    // {
    //   index: "2",
    //   name: "文章类型",
    //   path: "/config/articleType",
      
    //   component: "articleTypeList",
    //   typeIdentity: "articleType",
    // },
    
    // {
    //     index: "4",
    //     name: "景点类型",
    //     path: "/config/sceneTypeList",
        
    //     component: "sceneTypeList",
    //     typeIdentity: "sceneType",
    //   },
    
  ]

export default tabs;