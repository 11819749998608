<template>
    <section class="sectio">
      <div class="container">
        <h1 class="title has-text-centered">Travel Article Recommendations</h1>
        <div class="article-list">
          <div 
            v-for="(title, index) in titles"
            :key="index"
            class="article-card card"
            hoverable
          >
            <div class="card-content">
              <p class="title is-6">{{ title }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        titles: [
          "Discover the Hidden Gems of Bali",
          "Uncover the Best Cherry Blossoms in Japan",
          "Journey Through Europe’s Castles",
          "Explore the Lost City of Peru",
          "Dreamy Maldives: Sun, Sand, and Sea",
          "Adventure Through the Amazon Rainforest",
          "Explore the Lost City of Peru",
          "The Magic of Iceland: Exploring the Ice and Snow",
          "Safari Wonders in South Africa",
          "The Colors of India: A Jaipur Journey",
          "Dreamy Maldives: Sun, Sand, and Sea",
          "Experience the Stunning Landscapes of New Zealand",
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    /* max-width: 900px; */
    margin: 0 auto;
    padding: 10px;
  }
  
  .title {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: #4a4a4a;
  }
  
  .article-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Three cards per row */
    gap: 15px;
  }
  
  .article-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: linear-gradient(135deg, #f0f4f8, #e8f0fe);
  }
  
  .article-card:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .card-content {
    padding: 15px;
    text-align: center;
  }
  
  .card-content .title {
    font-weight: 600;
    color: #333;
  }
  </style>
  