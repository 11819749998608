<template>
    <div class="articleList">
      <!-- Existing content -->
  
      <!-- Footer Section -->
      <footer class="footer is-dark">
        <div class="content has-text-centered">
          <p class="title is-4 footerContent">Travel Enthusiasts</p>
          <p class="subtitle is-6 footerContent">Founded by a group of travel enthusiasts, we are passionate about discovering the diverse cultures, landscapes, and hidden gems of Asia. Our platform was created to provide travelers with comprehensive guides, local insights, and curated experiences that showcase the beauty of this incredible continent.</p>
  
          <!-- Random Social Media Links -->
          <div class="social-icons">
            <a href="https://facebook.com" target="_blank" class="icon">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://twitter.com" target="_blank" class="icon">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank" class="icon">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
  
          <!-- Random Navigation Links -->
          <nav class="footer-nav">
            <a href="/about-us">About Us</a> |
            <!-- <a href="/privacy-policy">Privacy Policy</a> | -->
            <a href="/contact">Contact Us</a> 
            <!-- | -->
            <!-- <a href="/faq">FAQ</a> -->
          </nav>
  
          <!-- Random Quote -->
          <p class="random-quote">"The world is a book, and those who do not travel read only one page." - Augustine of Hippo</p>
  
          <!-- Copyright -->
          <p>&copy; 2024 Travel Enthusiasts. All rights reserved.</p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Optionally add logic for dynamic content
      };
    },
  };
  </script>
  
  <style scoped>
  .footer {
    /* width:93%; */
    /* margin-left:7%; */
    padding: 2rem 1.5rem;
    background-color: #1a1a1a;
    color: #f0f0f0;
  }
  .footerContent{
    color:#d7c9c9;
  }
  .social-icons .icon {
    margin: 0 0.5rem;
    font-size: 1.5rem;
    color: #fff;
  }
  
  .footer-nav {
    margin: 1rem 0;
  }
  
  .footer-nav a {
    color: #ccc;
    margin: 0 0.5rem;
    text-decoration: none;
  }
  
  .random-quote {
    font-style: italic;
    margin: 1.5rem 0;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }
  </style>
  