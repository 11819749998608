<template>
    <section class="section wrap">
      <div v-for="(video, index) in videos" :key="index" class="video-container">
        <b-card class="video-card">
          <!-- 插槽形式展示标题，应用自定义样式 -->
          <b-card-header class="custom-card-header">
            <p class="custom-card-title">{{ video.title }}</p>
          </b-card-header>
  
          <!-- 视频内容 -->
          <video controls class="video-player" > 
            <source :src="video.src" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
  
          <!-- 插槽形式展示描述，应用自定义样式 -->
          <b-card-content class="custom-card-content">
            <div class="custom-card-description">{{ video.description }}</div>
          </b-card-content>
        </b-card>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        videos: [
          {
            title: "Beautiful Beach",
            description: "A trip to the stunning beaches of Bali.",
            src: "https://www.w3schools.com/html/mov_bbb.mp4"
          },
          {
            title: "Mountain Adventure",
            description: "Exploring the mountains in Nepal.",
            src: "https://samplelib.com/lib/preview/mp4/sample-5s.mp4"
          },
          {
            title: "City Lights",
            description: "The night view of Tokyo.",
            src: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
          },
          {
            title: "Safari Adventure",
            description: "Wildlife safari in Kenya.",
            src: "https://filesamples.com/samples/video/mp4/sample_640x360.mp4"
          },
          {
            title: "Desert Journey",
            description: "Camel riding in the Sahara Desert.",
            src: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
          },
          {
            title: "Historical Ruins",
            description: "Exploring ancient ruins in Rome.",
            src: "https://samplelib.com/lib/preview/mp4/sample-5s.mp4"
          },
          {
            title: "Waterfall Hike",
            description: "Hiking to the majestic waterfalls in Iceland.",
            src: "https://filesamples.com/samples/video/mp4/sample_640x360.mp4"
          },
          {
            title: "Snowy Peaks",
            description: "A winter trip to the Alps.",
            src: "https://www.w3schools.com/html/mov_bbb.mp4"
          }
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* 视频播放器样式 */
  .video-player {
    width: 100%;

    max-width: 250px;
    height: 20vh;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 卡片布局 */
  .video-card {
    margin-bottom: 1rem;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .video-card:hover {
    transform: scale(1.05); /* 鼠标悬停时缩放效果 */
  }
  
  /* 自定义卡片头部样式 */
  .custom-card-header {
    background-color: #f7f7f7;
    border-bottom: 2px solid #eee;
    padding: 10px;
    border-radius: 8px 8px 0 0;
  }
  
  /* 自定义卡片标题样式 */
  .custom-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 0;
    text-transform: uppercase;
  }
  
  /* 自定义卡片描述样式 */
  .custom-card-content {
    padding: 15px;
  }
  
  .custom-card-description {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.4;
    text-align: justify;
    margin-top: 10px;
  }
  
  /* 网格布局 */
  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .video-container {
    flex: 1 1 21%;
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
  }
  </style>
  