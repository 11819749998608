<template>
  <div class="headline">
    <iframe
          class="js-wegotrip-widget"
          width="100%"
          height="490px"
          frameborder="0"
          scrolling="no"
          style="margin-left: -10px; margin-right: -10px"
          :src="src"
        ></iframe>
  </div>
</template>



<script>
export default {
  methods: {
    getArticleAttributes() {
      let m=[
    "artashat-d1918",
    "ashtarak-d872",
    "gyumri-d1896",
    "yerevan-d159",
    "baku-d424",
    "ganja-d1197",
    "siem-reap-d2233",
    "beijing-d905",
    "kashgar-d1922",
    "shanghai-d773",
    "batumi-d148",
    "borjomi-d1291",
    "gori-d1883",
    "kazbegi-d2476",
    "kutaisi-d1251",
    "mtskheta-d1361",
    "tbilisi-d28",
    "hong-kong-d777",
    "kowloon-d1249",
    "agra-d476",
    "bengaluru-d787",
    "chennai-d892",
    "cochin-d1860",
    "delhi-d475",
    "dharamsala-d1892",
    "gangtok-d1902",
    "haldwani-d1326",
    "hampi-d2509",
    "jaipur-d307",
    "jaisalmer-d1882",
    "jodhpur-d1915",
    "kolkata-d1256",
    "mormugao-d1979",
    "mumbai-d759",
    "mussoorie-d1891",
    "naini-tal-d1365",
    "udaipur-1253986-d2068",
    "denpasar-d740",
    "yogyakarta-d1151",
    "yazd-d914",
    "jerusalem-d203",
    "tel-aviv-d84",
    "hashimoto-d2107",
    "hiroshima-d1252",
    "kamakura-d1195",
    "kyoto-d45",
    "matsuyama-d1035",
    "nara-shi-d1250",
    "osaka-d1089",
    "takayama-d2266",
    "tokyo-d220",
    "yokohama-d1046",
    "amman-d869",
    "almaty-d489",
    "beirut-d715",
    "kuala-lumpur-d972",
    "malacca-d971",
    "kathmandu-d269",
    "singapore-d40",
    "seoul-d767",
    "galle-d784",
    "matale-d2300",
    "taipei-d38",
    "bangkok-d204",
    "phuket-d142",
    "samut-prakan-d2535",
    "alanya-d145",
    "ankara-d1231",
    "antalya-d86",
    "bodrum-d1230",
    "bursa-d1298",
    "istanbul-d29",
    "izmir-d1232",
    "abu-dhabi-d90",
    "al-ain-city-d889",
    "dubai-d89",
    "sharjah-d294",
    "bukhara-d493",
    "khiwa-d1248",
    "samarkand-d354",
    "tashkent-d748",
    "hue-d1914"
];
      console.log(this.$route.path,222223);
      this.src=`https://wegotrip.com/en/widgets/?perPage=8&cityId=${this.cityId}&coupon=4929&showMore=true&preorder=true`
    },
  },
  data() {
    return {
      article: {},
      cityId:905
    };
  },
  created() {
    this.getArticleAttributes();
  },
};
</script>
<style scoped lang="scss">
.headline{
  min-height:80vh;
}
</style>