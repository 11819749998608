<template>
  <div class="header container">
    <b-navbar fixed-top type="is-white" spaced centered>
      <!-- Brand Section -->
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }" class="brand-item">
          <b-icon icon="earth" class="mr-2"></b-icon>
          <span class="brand-title"><h1>Leisure Trip in Asia</h1></span>
        </b-navbar-item>
      </template>

      <!-- Start of Navbar -->
      <template #start>
        <!-- <b-navbar-item href="/">
          <span class="menu-item">Home</span>
        </b-navbar-item> -->
        <!-- <b-navbar-item href="/hots">
          <span class="menu-item">Hot Destinations</span>
        </b-navbar-item> -->
        <!-- <b-navbar-item href="/news">
          <span class="menu-item">Latest News</span>
        </b-navbar-item>
        <b-navbar-item href="/scenes">
          <span class="menu-item">Top Scenes</span>
        </b-navbar-item> -->
      </template>

      <!-- End of Navbar (User Login/Profile) -->
      <template #end>
        <b-navbar-item href="/contact">
          <span class="menu-item">Contacts</span>
        </b-navbar-item>
        <b-navbar-item>
          <!-- <div class="buttons"> -->
            <!-- User Dropdown if logged in -->
            <b-dropdown v-if="userName" v-model="navigation" append-to-body aria-role="menu">
              <template #trigger>
                <a class="navbar-item user-trigger">
                  <span class="user-name">{{ userName }}</span>
                  <b-icon icon="menu-down" class="ml-2"></b-icon>
                </a>
              </template>
              <b-dropdown-item value="logout" aria-role="menuitem" @click="logoutBtn">
                <b-icon icon="logout" class=""></b-icon>Log out
              </b-dropdown-item>
            </b-dropdown>

            <!-- Log in button if not logged in -->
            <b-button v-if="!userName" type="is-primary" size="is-small" class="login-btn" @click="LoginBtn">
              Log in
            </b-button>
          <!-- </div> -->
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: "",
    };
  },
  methods: {
    logoutBtn() {
      localStorage.removeItem("crm-token");
      localStorage.removeItem("crm-userName");
      window.location.href="/login"
      this.$forceUpdate()
    },
    LoginBtn() {
      this.$router.push("/login");
    },
    getToken() {
      let t = localStorage.getItem("crm-token");
      if (t&&t.length > 0) {
        let uName = localStorage.getItem("crm-userName");
        if (uName.length > 0) {
          this.userName = uName;
          this.$forceUpdate()
        } 
      }else {
          if(this.$route.path.includes("/config")){
            this.$router.push("/login");
          } else{
            console.log(this.$route.path,33);
            // this.$router.push("/login")
          }
          
        }
    },
  },
  created() {
    this.getToken();
  },
};
</script>


<style lang="sass" scoped>
.header {
  margin-top: 0;
  background:#4f3939;
}

.brand-item {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(31,33,41);
  
}

.brand-title {
  color:rgb(31,33,41)!important;
  font-weight: bold;
  font-size: 1.5rem;
  transition: color 0.3s ease;
 
}
.brand-title>h1{
  color:rgb(31,33,41)!important;
}
.brand-title:hover {
  color: #ffdd57; /* Highlight color for brand on hover */
}

.menu-item {
  font-size: 1.1rem;

  text-transform: capitalize;
  margin-right: 15px;
  padding: 5px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.menu-item:hover {
  color: #ffdd57; /* Highlight color */
  background-color: rgba(255, 255, 255, 0.1); /* Subtle background on hover */
}

.user-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-name {
  font-size: 1rem;
 
}
.user-name:hover{
  color:#4f3939!important;
}
.login-btn {
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #ffdd57; /* Highlight color for login button */
}

.b-dropdown-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.b-dropdown-item b-icon {
  font-size: 1.2rem;
}

.navbar-item :hover{
  font-weight:bold;
}

.b-button.is-primary {
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.b-button.is-primary:hover {
  background-color: #00b89c;
  border-color: #00b89c;
}
</style>