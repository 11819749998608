<template>
    <div class="articleForm box">
      <!-- {{ parentData }} -->
    
      <div class="title is-5 is-success"> {{ (parentData.id ? "编辑 " : "创建 " )+"国家或城市"}}</div>
      
      <section class="box">
        <b-field label="城市ID :" horizontal v-if="parentData.id">
          {{ parentData.id }}
        </b-field>
        <b-field label="英文名称:" horizontal>
          <b-input
            size="is-small"
            v-model="parentData.name"
            required
          ></b-input>
        </b-field>
       

  
        <b-field label="国家或省份:" horizontal>
          <b-select size="is-small" placeholder="选择国家或省份" v-model="parentData.parentId">
            <option
              v-for="option in cityList"
              :value="option.id"
              :key="option.name"
            >
              {{ option.name}}
            </option>
          </b-select>
        </b-field>
        <b-field label="是否热门" horizontal>
            <b-radio
              size="is-small"
              v-model="parentData.isHot"
              v-for="(type, index) in statusList"
              :key="index"
              :native-value="type.value"
              type="is-success  is-outlined"
            >
              {{ type.label }}
            </b-radio>
          </b-field>
      
  
 

        <b-field label="旅游季节" horizontal>
          <b-checkbox v-model="seasonToVisitList"
                native-value="spring"
                type="is-info is-light is-outlined" size="is-small">
              
                <span>Spring</span>
            </b-checkbox>

            <b-checkbox v-model="seasonToVisitList"
                native-value="summer"
                type="is-warning is-light is-outlined" size="is-small"> 
               
                <span>Summer</span>
            </b-checkbox>
            <b-checkbox v-model="seasonToVisitList"
                native-value="autumn"
                type="is-success is-light is-outlined" size="is-small"> 
               
                <span>Autumn</span>
            </b-checkbox>
            <b-checkbox v-model="seasonToVisitList"
                native-value="winter"
                type="is-success is-light is-outlined" size="is-small"> 
               
                <span>Winter</span>
            </b-checkbox>
            
        </b-field>
        <b-field label="旅游月份" horizontal>
          <!-- <b-input
            size="is-small"
            :value="parentData.monthToVisitList"
            required
            type="number"
            min="1"
            max="12"
          >
          </b-input> -->
          <section>
            <b-checkbox v-model="monthToVisitList" v-for="(item,index) in months" :native-value="item" :key="item" size="is-small"> {{ item }}</b-checkbox>
            
          </section>
         
        </b-field>
        <b-field label="城市描述:" horizontal>
        <b-input
          size="is-small"
          :value="parentData.desc"
          v-model="parentData.desc"
          required
        ></b-input>
      </b-field>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
        <b-button type="is-primary is-success" size="is-small" @click="confirmBtn">确认</b-button></div></div>
  
  
      </div>
      </section>
  
      <!-- {{ parentData }} -->
    </div>
  </template>
  <script>
  import postData from "../../../common/http";
  export default {
      watch:{
          
      },
      updated(){
          
          this.getAllType()
      },
    props: {
      parentData: {
        type: Object,
      },
    },
    methods: {
      async getList(type) {
        let list = await postData(this.commonTypeListPath, {
          json: {
            typeIdentity: type,
          },
        });
        
        this.typeList=list;
      },
      async confirmBtn(){
          
          if(!this.parentData.id)delete this.parentData.id;
          let data=await postData(this.savePath,{
            json:{...this.parentData,
              parentId:this.parentData.parentId||0,
              seasonToVisitList:this.seasonToVisitList,
              monthToVisitList:this.monthToVisitList
            }
          })
          console.log(data,339)
          if (data == null) {
            this.$buefy.toast.open("成功")
          }
          
      },
     async getAllType() {
      
        this.getList("tripType").then(data=>{
          this.TypeList=data;
        });
        let cityList=await postData(this.cityListPath,{
          json:{}
        })
        // console.log(cityList,777)
       
      },
      async getCityList(){
        let list=await postData(this.cityListPath,{
          json:{
            parentId:0
          }
        });
       this.cityList=list;
      }
    },
    data() {
      return {
        months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        statusList:[{label:"是",value:1},{label:"否",value:0}],
        seasonToVisitList:[],
        monthToVisitList:[],
        typeIdentity: "sceneType",
        commonTypeListPath: "/commonType/list",
        typeList: ["tripType", "tag", "keyword", "articleType", "transportType"],
        urlStatus: "is",
        tripList: [],
        articleTypeList: [],
        tripTypeList: [],
        cityList: [{
          name:"null",
          parentId:0
        }],
        cityListPath:"/city/list",
        transportTypeList: [],
        savePath:"/city/update",
      };
    },
    created() {
       const {seasonToVisitList,monthToVisitList} =this.parentData;
       this.seasonToVisitList=seasonToVisitList||[];
       this.monthToVisitList=monthToVisitList||[];
      this.getAllType();
      this.getCityList()
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .articleForm{
      padding-bottom:100px;
      min-height:95vh;
  }
  </style>