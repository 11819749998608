<template>
  <div class="article">
    <section class="level">
      <div class="level-left">
        <div class="level-item"></div>
      </div>

      <div class="level-right">
        <b-field class="level-item">
          <b-button
            size="is-small"
            icon-left="plus"
            type="is-success"
            @click="edit"
            >添加</b-button
          >
        </b-field>
      </div>
    </section>
   
    <b-table
      size="is-small"
      :stickyHeaders="stickyHeaders"
      :opened-detailed="defaultOpenedDetails"
      detailed
      :data="list"
      paginated
      per-page="70"
      hoverable
      :debounce-search="1000"
      :loading="!list.length > 0"
      :show-detail-icon="showDetailIcon"
      @details-open="(row) => showChildren(row)"
    >
      <b-table-column
        v-for="(column, index) in columnsTemplate"
        :key="index"
        :label="column.title"
        v-slot="props"
      >
        <div v-if="column.field == 'name'">
          {{ props.row[column.field] }}
        </div>

        <div
          v-if="
            column.field == 'seasonToVisitList' ||
            column.field == 'monthToVisitList'
          "
        >
          <b-tag
            type="is-link is-light"
            size="is-small"
            v-for="(item, index) in props.row[column.field]"
            :key="index"
            >{{ item }}</b-tag
          >
        </div>

        <span v-if="column.field == 'id' || column.field == 'desc'">{{
          props.row[column.field]
        }}</span>
        <span v-if="column.field == 'isHot'">{{
          props.row[column.field] == 1 ? "是" : "否"
        }}</span>
      </b-table-column>

      <b-table-column label="操作" v-slot="props">
        <div class="controlPanel">
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            @click="edit(props.row)"
            type="is-info"
          >
            编辑
          </b-button>

          <b-button
            size="is-small"
            icon-left="delete"
            @click="deleteBtn(props.row)"
            type="is-danger"
          >
            删除
          </b-button>
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="cityContainer columns">
          <div
           
            class="citycard card"
            v-for="(item, index) in children"
            :key="index"
          >
            <header class="card-header">
              <p class="card-header-title">{{ item.name }}</p>
            </header>
            <div class="card-content">
              <div class="content">
                <h1>{{ item.name }}</h1>

                <p>
                  最佳旅游季节：
                  <b-tag
                    type="is-link is-light"
                    size="is-small"
                    v-for="(value, index) in item.seasonToVisitList"
                    :key="index"
                    >{{ value }}</b-tag
                  >
                </p>
                <p>
                  最佳旅游月份：
                  <b-tag
                    type="is-link is-light"
                    size="is-small"
                    v-for="(value, index) in item.monthToVisitList"
                    :key="index"
                    >{{ value }}</b-tag
                  >
                </p>

                <p>
                  描述：<b-tag type="is-link is-light" size="is-small">
                    {{ item.desc }}</b-tag
                  >
                </p>
              </div>
            </div>
            <footer class="card-footer">
              <a href="javascript:" class="card-footer-item" @click="edit(item)"
                >Edit</a
              >
              <a href="#" class="card-footer-item">Delete</a>
            </footer>
          </div>
          <div class="addNewCity" v-if="children.length==0">
            
              <b-button type="is-success" size="is-small" @click="addChildren(props.row)">添加城市 </b-button>
            
          </div>
        </div>
      </template>
    </b-table>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="openDrawer"
      :on-cancel="closeSideBar"
    >
      <cityForm :parentData="dataToForm" v-if="openDrawer"></cityForm>
    </b-sidebar>
  </div>
</template>
  <script>
import cityForm from "./cityForm.vue";
import postData from "../../../common/http";
export default {
  data() {
    return {
      deletePath: "/city/delete",
      children: [],
      typeIdentity: "",
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      openSideBar: false,
      fullwidth: true,
      stickyHeaders: true,
      showAllColumns: false,
      fullheight: true,
      right: true,
      isOverlay: true,
      openDrawer: false,
      statusList: [
        "draft",
        "created",
        "reviewed",
        "scheduled",
        "published",
        "deleted",
      ],
      listPath: "/city/list",

      list: [
        {
          id: 1,
          name: " China",

          isHot: true,
        },
        {
          id: 2,
          name: "Sin",
          isHot: false,
        },
      ],
      columnsTemplate: [
        { title: "ID", field: "id", searchable: true },
        {
          title: "城市名称",
          field: "name",

          searchable: true,
        },
        { title: "父节点", field: "parentId" },
        { title: "最佳旅游季节", field: "seasonToVisitList" },
        { title: "最佳旅游月份", field: "monthToVisitList" },
        { title: "是否热门", field: "isHot" },
        { title: "描述", field: "desc" },
      ],
    };
  },
  methods: {
    async getList() {
      let data = await postData(this.listPath, {
        json: {
          cityId: 0,
          pageNo: 1,
          pageSize: 90,
        },
      });

      this.list = data;
    },
    toggleColumns() {
      this.showAllColumns != this.showAllColumns;
    },
    async deleteBtn(item) {
      console.log(item.id,item,33333)
      let data = await postData(this.deletePath, {
        json: {id:item.id},
      });
      console.log(data,33)
      if(data==null){
        this.$buefy.toast.open("success");
        this.getList();
      }
    },
    edit(item) {
      this.dataToForm = item.id ? item : {};
      this.openDrawer = true;
      this.openSideBar = true;
    },
    addChildren(item){
      this.dataToForm={parentId:item.id,isHot:0};
      this.openDrawer=true;
      this.openSideBar=true;
    },
    closeSideBar() {
      this.openSideBar = false;
    },
    showChildren(item) {
      this.getChildren(item);
    },
    async getChildren(item) {
      let list = await postData(this.listPath, {
        json: { parentId: item.id },
      });
      if (list) {
        this.children = list;
      }
    },
  },
  created() {
    this.getList();
  },
  watch: {},
  components: { cityForm },
};
</script>
  
  <style lang="scss" scoped>
.article {
  width: 100%;
  // display:flex;
  // flex-wrap:wrap;
  // justify-content: space-between;
}
.sidebar-content {
  min-width: 500px !important;
}
.controlPanel {
  display: flex;
  justify-content: space-around;
}
.cityContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .citycard {
    width: 15rem;
    margin: 2rem 0;
  }
}
.addNewCity{
  width:100%;
  display:flex;
  justify-content: center;
}
</style>
  
  
  