<template>
  <div class="login">
   
    <div class="box boxContainer">
        <h1 class="title is-h2">welcome</h1>
      <b-field
        label="Email address"
        type="is-danger"
       
      >
        <b-input type="email" value="john@" maxlength="30" v-model="user.userName"> </b-input>
      </b-field>
      <b-field label="Password">
            <b-input type="password"
                value="iwantmytreasure"
                password-reveal v-model="user.password">
            </b-input>
        </b-field>
        <div class="content">
            <b-button type="is-success" size="is-small" expanded @click="loginBtn">登录</b-button>
        </div>
        <div class="level is-mobile">
            <div class="level-item"> <a href="">乐行亚洲</a></div>
           <div class="level-item"> <span>|</span></div>
           
          <div class="level-item">  <a href="">乐行天下</a></div>
        </div>
        
    </div>
  </div>
</template>
<script>
import postData from '@/common/http';
export default {
    data(){
        return{
            user:{},
            loginPath:"/login"

    }},
    methods:{
        async loginBtn(){
            let data=await postData(this.loginPath,{
                json:this.user
            })
            if(data.token&&data.userName){
              localStorage.setItem("crm-userName",data.userName);
                localStorage.setItem("crm-token",data.token);
                window.location.href="/config/articleList";
                this.$forceUpdate()
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.login {
    margin-top:10rem;
    
  display: flex;
  justify-content: center;
  .boxContainer {
  
    width: 30%;
    min-width:280px;
    height:20rem;
  }
}
</style>