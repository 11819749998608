<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-centered">Latest Travel News in Asia</h1>
      
      <div class="columns is-multiline">
        <div class="column is-4" v-for="news in articles" :key="news.title">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img :src="news.image" :alt="news.title" />
              </figure>
            </div>
            <div class="card-content">
              <h3 class="title is-4">{{ news.title }}</h3>
              <p>{{ news.description }}</p>
              <a :href="news.link" class="button is-link">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      articles: [
        {
          title: "Exploring Hidden Gems in Vietnam",
          description: "Discover the lesser-known destinations in Vietnam that are rich in culture and beauty.",
          image: "https://example.com/vietnam.jpg",
          link: "https://example.com/article-vietnam"
        },
        {
          title: "Japan’s Cherry Blossom Season",
          description: "Experience the breathtaking beauty of cherry blossoms across Japan this spring.",
          image: "https://example.com/japan.jpg",
          link: "https://example.com/article-japan"
        },
        {
          title: "Thailand Reopens to Tourists",
          description: "Thailand welcomes back travelers with new safety measures in place.",
          image: "https://example.com/thailand.jpg",
          link: "https://example.com/article-thailand"
        },
        {
          title: "Singapore’s Food Festival",
          description: "Join the annual food festival showcasing Singapore's diverse culinary delights.",
          image: "https://example.com/singapore.jpg",
          link: "https://example.com/article-singapore"
        },
        {
          title: "Adventure Awaits in Bali",
          description: "Discover the top adventure activities you can enjoy in Bali.",
          image: "https://example.com/bali.jpg",
          link: "https://example.com/article-bali"
        },
        {
          title: "Cultural Insights from India",
          description: "Dive into the rich cultural heritage and traditions of India.",
          image: "https://example.com/india.jpg",
          link: "https://example.com/article-india"
        }
      ]
    };
  }
};
</script>

<style scoped>
.section {
  padding: 3rem 1.5rem;
}

.card {
  margin-bottom: 1.5rem;
}

h1 {
  color: #363636;
}

.button {
  margin-top: 1rem;
}
</style>
