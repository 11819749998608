import { render, staticRenderFns } from "./aboutus.vue?vue&type=template&id=7697d4a3&scoped=true"
import script from "./aboutus.vue?vue&type=script&lang=js"
export * from "./aboutus.vue?vue&type=script&lang=js"
import style0 from "./aboutus.vue?vue&type=style&index=0&id=7697d4a3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7697d4a3",
  null
  
)

export default component.exports