<template>
  <div class="search level">
    <div class="level-item leftContainer">
      <article class="panel is-success searchBox">
      
        <p class="panel-tabs panel-block countryTabList level">
          <a class="HOT countryTab level-item" disabled>
            <div>HOT</div></a
          >
          <a
            :class="{
              countryTab: true,
              'level-item': true,
            }"
            v-for="(data, index) in tourismData"
            :key="index"
            ><h2>
              <a :href="`/popular/` + data.country" @mouseover="changeContent(data.country)">{{ data.country }}</a>
            </h2></a
          >
        </p>

        <a
          class="panel-block level"
          v-for="(type, index) in hotTypes"
          :key="index"
        >
          <div class="level-item HOT has-text-centered typeName">
            <h2>
              <a :href="format(type)">{{ type }}</a>
            </h2>
          </div>

          <div
            class="level-item typeTab has-text-centered"
            v-for="(data, index) in tourismData"
            :key="index"
          >
            <div class="cityTab">
              <div
                v-for="(city, index) in data.types[type]"
                :key="index"
                class="heading"
              >
                <h3>
                  <a :href="formateCity(data, city, type)">{{ city }}</a>
                </h3>
              </div>
            </div>
          </div>
        </a>
      </article>
    </div>
    <div class="level-item rightContainer">
      <div class="box rightItem" v-for="(src,index) in pictures" :key="index">

        <div class="card">
          <div class="car-image">
            <img :src="backgroundImage(src)" alt="">
          </div>
        </div>
        <div class="card-content">
          Japan has many popular destinations and pictures
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictures:[
  "/assets/img/japan.jpg","/assets/img/japan.jpg","/assets/img/japan.jpg","/assets/img/japan.jpg","/assets/img/japan.jpg",
      ],
      hotCountries: [
        "Japan",
        "Thailand",
        "China",
        "India",
        "Vietnam",
        // "South Korea",
        "Indonesia",
        // "Maldives",
      ],
      hotTypes: [
        "Luxury Travel",
        "Adventure Tour",
        "Culture & History",
        "Wellness & Spa",
      ],
      tourismData: [
        {
          country: "Japan",
          types: {
            "Luxury Travel": ["Tokyo", "Kyoto", "Osaka"],
            "Adventure Tour": ["Hokkaido", "Mount Fuji", "Okinawa"],
            "Culture & History": ["Kyoto", "Nara", "Kanazawa"],
            "Wellness & Spa": ["Hakone", "Beppu", "Kusatsu"],
          },
        },
        {
          country: "Thailand",
          types: {
            "Luxury Travel": ["Bangkok", "Phuket", "Koh Samui"],
            "Adventure Tour": ["Chiang Mai", "Krabi", "Koh Tao"],
            "Culture & History": ["Ayutthaya", "Sukhothai", "Chiang Mai"],
            "Wellness & Spa": ["Phuket", "Koh Samui", "Chiang Mai"],
          },
        },
        {
          country: "China",
          types: {
            "Luxury Travel": ["Shanghai", "Beijing", "Hong Kong"],
            "Adventure Tour": ["Zhangjiajie", "Guilin", "Tibet"],
            "Culture & History": ["Xi'an", "Beijing", "Luoyang"],
            "Wellness & Spa": ["Hangzhou", "Sanya", "Lijiang"],
          },
        },
        {
          country: "India",
          types: {
            "Luxury Travel": ["Mumbai", "New Delhi", "Udaipur"],
            "Adventure Tour": ["Rishikesh", "Leh-Ladakh", "Goa"],
            "Culture & History": ["Jaipur", "Agra", "Varanasi"],
            "Wellness & Spa": ["Kerala", "Rishikesh", "Goa"],
          },
        },
        {
          country: "Vietnam",
          types: {
            "Luxury Travel": ["Hanoi", "Ho Chi Minh City", "Da Nang"],
            "Adventure Tour": ["Ha Long Bay", "Sapa", "Phong Nha"],
            "Culture & History": ["Hue", "Hoi An", "Hanoi"],
            "Wellness & Spa": ["Da Nang", "Nha Trang", "Phu Quoc"],
          },
        },
        // {
        //   country: "South Korea",
        //   types: {
        //     "Luxury Travel": ["Seoul", "Busan", "Jeju Island"],
        //     "Adventure Tour": ["Jeju Island", "Seoraksan", "Gangwon-do"],
        //     "Culture & History": ["Gyeongju", "Seoul", "Andong"],
        //     "Wellness & Spa": ["Jeju Island", "Busan", "Gangwon-do"],
        //   },
        // },
        {
          country: "Indonesia",
          types: {
            "Luxury Travel": ["Bali", "Jakarta", "Lombok"],
            "Adventure Tour": ["Komodo Island", "Bali", "Sumatra"],
            "Culture & History": ["Yogyakarta", "Ubud", "Solo"],
            "Wellness & Spa": ["Bali", "Lombok", "Ubud"],
          },
        },
        // {
        //   country: "Maldives",
        //   types: {
        //     "Luxury Travel": ["Male", "Addu Atoll", "Baa Atoll"],
        //     "Adventure Tour": ["Ari Atoll", "Vaavu Atoll", "Malé Atoll"],
        //     "Culture & History": ["Malé", "Hulhumale", "Utheemu"],
        //     "Wellness & Spa": ["Baa Atoll", "Malé Atoll", "Addu Atoll"],
        //   },
        // },
      ],
      
    };
  },
  methods: {
    changeContent(data){
      console.log(data,33)
    },
    backgroundImage(src){
     
      return require(`@/assets/img/japan.jpg`);
    },
    formateCity(data, city, type) {
      // console.log(data, city, type, 3);

      return (
        "/travel/" +
        (type.includes("&")
          ? type.split("&").join("-")
          : type.split(" ").join("-")) +
        "/" +
        data.country +
        "/" +
        (city.includes(" ") ? city.split(" ").join("-") : city)
      );
    },
    format(type) {
      if (type.includes("&")) {
        return type.split(" & ").join("-");
      } else {
        return type.split(" ").join("-");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  background: #f7f8fc;
  height: 50vh;
  overflow:hidden;
  // min-height: 500px;
  width: 100%;
  .leftContainer {
    width: 75%;
    height: 50vh;
    min-height: 500px;
    // border:1px solid red;
    box-sizing: border-box;
    .searchBox {
      width: 100%;
      background: #fff;
      height: 50vh;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .panel-block {
        margin: 0;
        padding: 0;
        height: 100%;
        .typeName {
          font-size: 0.875rem;
          color: #7957d5;
        }
        .cityTab {
          width: 5rem;
          .heading {
            color: #333; /* 默认颜色 */
            font-size: 0.8rem;
            transition: color 0.3s ease, text-decoration 0.3s ease;
            cursor: pointer; /* 添加指针样式 */
          }

          .heading:hover {
            color: #ff6347; /* 悬停时文字颜色 */
            text-decoration: underline; /* 悬停时添加下划线 */
          }
        }
      }
      .countryTabList {
        display: flex;
        justify-content: flex-start;
        border-bottom: none;
        .countryTab,
        .typeTab {
          text-align: center;
          .link {
            color: #7957d5;
          }
        }
        .HOT {
          width: 6rem;
          text-align: center;
          cursor: default;
          font-weight: bold;
          color: #ffcc32;
        }
      }
    }
  }
  .rightContainer {
    padding-top: 20rem;

    display: flex;
    // border:1px solid red;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 0.2rem 1rem;
    // margin-left: 1rem;

    width: 20%;
    height: 50vh;
    min-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    // border:1px solid red;

    .rightItem {
      width: 100%;
      //   height: 10vh;
      //  margin:1rem 3rem;
    }
  }
}

/* 手机设备（max-width: 767px） */
@media only screen and (max-width: 767px) {
  * {
    font-size: 0.55rem;
  }
  .search {
    display: flex;
    flex-direction: column;
    height: 90vh;
    .leftContainer {
      width: 100%;
      .searchBox {
        .panel-block {
          width: 100vw;
          overflow: hidden;
          overflow-x: scroll;
          .level-item {
            margin-bottom: 0;
          }
          .typeName {
            font-size: 0.55rem;
            width: 2rem;
            padding-left: 5px;
          }
          .cityTab {
            .heading {
            }

            .heading:hover {
            }
          }
        }
        .panel-block::-webkit-scrollbar {
          display: none; /* Chrome, Safari 和 Opera */
        }
        .countryTabList {
          width: 100vw;
          overflow: hidden;
          overflow-x: scroll;
          .countryTab,
          .typeTab {
            font-size: 0.55rem;
            .link {
            }
          }
          .HOT {
            width: 2rem;
          }
        }
      }
    }
    .rightContainer {
      width: 100%;
      margin: 0;
      height: 60vh;
      .rightItem {
        height: 5vh;
      }
    }
  }
}

/* 平板设备（min-width: 768px）到（max-width: 1024px） */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .search {
    height: 40vh !important;
    .leftContainer {
      height: 40vh !important;
      .searchBox {
        height: 40vh !important;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        .panel-block {
          .typeName {
          }
          .cityTab {
            .heading {
            }

            .heading:hover {
            }
          }
        }
        .countryTabList {
          .countryTab,
          .typeTab {
            .link {
            }
          }
          .HOT {
          }
        }
      }
    }
    .rightContainer {
      .rightItem {
      }
    }
  }
}

/* 小型桌面（min-width: 1025px）到（max-width: 1200px） */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .search {
    .leftContainer {
      .searchBox {
        .panel-block {
          .typeName {
          }
          .cityTab {
            .heading {
            }

            .heading:hover {
            }
          }
        }
        .countryTabList {
          .countryTab,
          .typeTab {
            .link {
            }
          }
          .HOT {
          }
        }
      }
    }
    .rightContainer {
      .rightItem {
      }
    }
  }
}

/* 标准桌面（min-width: 1200px）到（max-width: 1440px） */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .search {
    .leftContainer {
      .searchBox {
        .panel-block {
          .typeName {
          }
          .cityTab {
            .heading {
            }

            .heading:hover {
            }
          }
        }
        .countryTabList {
          .countryTab,
          .typeTab {
            .link {
            }
          }
          .HOT {
          }
        }
      }
    }
    .rightContainer {
      .rightItem {
      }
    }
  }
}

/* 大屏桌面（min-width: 1440px） */
@media only screen and (min-width: 1440px) {
  .search {
    .leftContainer {
      .searchBox {
        .panel-block {
          .typeName {
          }
          .cityTab {
            .heading {
            }

            .heading:hover {
            }
          }
        }
        .countryTabList {
          .countryTab,
          .typeTab {
            .link {
            }
          }
          .HOT {
          }
        }
      }
    }
    .rightContainer {
      .rightItem {
      }
    }
  }
}
</style>