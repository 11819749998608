<template>
  <div class="mainRightContainer box">
    <div v-for="i in 6" :key="i" class="headlineContainer">
      <h4 class="title is-6">{{ getRandomMeaningfulCombination() }}</h4>
      
      |<h4 class="title is-6">{{ getRandomMeaningfulCombination() }}</h4>
     
    </div>
    <div class="headlineContainer">
    
      |
      <h4 class="title is-6" v-text="getRandomMeaningfulCombination()"></h4>
      <a href="">All New Updates</a>
      

    </div>
  </div>
</template>
<script>
export default{
  methods:{
    getRandomMeaningfulCombination() {
      const travelKeywords = {
    transportation: [
        "flights", "car rentals", "train tickets", "cheap flights", "cruise deals", "flight comparison", "airport transfers", "best flight prices"
    ],
    accommodation: [
        "hotels", "vacation rentals", "boutique hotels", "hostels", "luxury stays", "budget accommodation", "unique stays", "beach houses", "family-friendly hotels"
    ],
    activities: [
        "local experiences", "tours", "things to do", "sightseeing", "adventure tours", "guided tours", "museums", "city walks", "nature hikes"
    ],
    deals: [
        "last-minute travel", "exclusive offers", "holiday packages", "travel discounts",  "best price guarantee", "vacation deals", "group travel deals"
    ]
};
    // 随机选择2到5个组合的关键词
    const numKeywords = Math.floor(Math.random() * 4) + 2; // 2到5个关键词组合
    
    // 按照分类，从每个类别中随机取出一个关键词
    const selectedKeywords = [];

    if (numKeywords >= 2) selectedKeywords.push(travelKeywords.transportation[Math.floor(Math.random() * travelKeywords.transportation.length)]);
    if (numKeywords >= 3) selectedKeywords.push(travelKeywords.accommodation[Math.floor(Math.random() * travelKeywords.accommodation.length)]);
    if (numKeywords >= 4) selectedKeywords.push(travelKeywords.activities[Math.floor(Math.random() * travelKeywords.activities.length)]);
    if (numKeywords >= 5) selectedKeywords.push(travelKeywords.deals[Math.floor(Math.random() * travelKeywords.deals.length)]);

    // 将组合后的关键词返回为一个字符串
    return selectedKeywords.join(", ");
}
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="scss" scoped>
.mainRightContainer {
  width: 100%;
  height:40vh;
  overflow:hidden;
  overflow-y:scroll;
  padding: 0 1rem;
  margin:  0;
  margin-left:1rem;
  background:#fff;
  .headlineContainer {
    min-height:5vh;
    display:flex;
    align-items:center;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>