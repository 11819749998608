<template>
    <div class="scene">
        this is scene
    </div>
</template>

<script>
export default{

}
</script>
<style lang="scss" scoped>

</style>