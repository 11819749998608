<template>
  <div class="prop">
    <b-carousel class="carouselContainer" :indicator="indicator" :autoplay="autoplay">
      <b-carousel-item
        v-for="(desc, index) in destinations"
        :key="index"
        :style="{
          backgroundImage: `url(${backgroundImage(index)})`,
          backgroundSize: 'cover',
        }"
      >
        <section :class="`hero is-small is-${desc.color} heroContainer`">
          <div class="hero-body blogContent" @click="jump(desc)">
            <!-- <h2 class="title titleContent"> -->
              <!-- {{ desc.title }} -->
              <a :href="getLink(desc)" class="title titleContent"><h2>{{ desc.title }}</h2></a>
            <!-- </h2> -->
            <!-- <div
              class="subtitle"
              v-for="(title, index) in desc.subtitle"
              :key="index"
            >
              <div class="content" v-if="index%2">
                <h3 class="contentTitle">
                  {{ title }}
                </h3>
              </div>
            </div> -->
            <div class="descContainer" >
              <div class="desc">{{ desc.description }}</div>
              <b-button
                type="is-link is-light"
                inverted
                outlined
                size="is-small"
                >Enjoy More</b-button
              >
            </div>
          </div>
        </section>
      </b-carousel-item>
      <b-carousel-item>
        <section class="hero heroContainer">
          <div class="title is-1">this is ads</div>
        </section>
      </b-carousel-item>
    </b-carousel>
    <!-- <div
      :class="{ hero: true, 'is-success': true, 'is-small': true }"
      v-for="(desc, index) in destinations"
      :key="index"
      :style="{ backgroundImage: `url(${backgroundImage(index)})` }"
    ></div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
        autoplay:true,
        indicator:false,
      destinations: [
        {
          title: "Explore Popular Countries",
          subtitle: [
            "Discover the top countries like Japan, Thailand, and Indonesia.",
            "Best cities to visit , from vibrant Tokyo to serene Bali.",
          ],
          description:
            "Dive into a curated list of top countries to visit and uncover the hidden gems each destination has to offer.",
        },
        {
          title: "Trending Travel Insights",
          subtitle: [
            "Discover exciting travel guides, adventure stories, and local insights.",
            "Browse stories tailored to your destination with exclusive ads for travel deals.",
          ],
          description:
            "Stay with the latest and most engaging travel stories with unique insights and inspiration for your next journey.",
        },
        {
          title: "Top Attractions to Visit",
          subtitle: [
            "From the Great Wall of China to Angkor Wat in Cambodia, find out which attractions you can't miss.",
            "Check out curated content, sponsored ads, and deals on guided tours to these world-famous spots.",
          ],
          description:
            "Explore must-see attractions around the world, with recommendations and tips for making the most of your visit.",
        },
        {
          title: "Must-Try Food & Drink",
          subtitle: [
            "Embark on a culinary adventure with our guide to the best restaurants, street food, and bars.",
            "From sushi in Japan to Pad Thai in Thailand, discover where to eat and drink on your travels.",
          ],
          description:
            "comprehensive and best local cuisine and dining experiences, even street food and bars",
        },
        {
          title: "Essential Travel Guides",
          subtitle: [
            "Prepare for your trip with in-depth travel guides covering the best routes, safety tips, and more.",
            "From first-time travelers to seasoned adventurers, our guides offer something for everyone.",
          ],
          description:
            "Get the essential information you need to plan your trip effectively, expert advice and detailed itineraries.",
        },
        {
          title: "Cheap Flights and Hotels",
          subtitle: [
            "Find unbeatable deals on flights and accommodations for your next adventure.",
            "Browse through our partners' exclusive offers and save on your next trip.",
          ],
          description:
            "Discover the best available travel deals, discounted flights and accommodations ",
        },
        {
          title: "Best Hotels to Stay In",
          subtitle: [
            "Read about the top-rated hotels around the world and enjoy exclusive booking offers.",
            "Whether you're looking for luxury or budget-friendly stays, we’ve got you covered.",
          ],
          description:
            "Find the perfect place to stay with highly recommended hotels with booking options and insider tips.",
        },
        {
          title: "News This Week",
          subtitle: [
            "Stay updated with the latest travel articles, news, and trending destinations.",
            "Be the first to explore newly opened attractions, hidden gems, and unique experiences.",
          ],
          description:
            "Keep up with the freshest content and latest updates in travel, featuring new attractions and emerging trends.",
        },
      ],
    };
  },
  methods: {
    getLink(item){
      return item.title.split(" ").join("-");
    },
    backgroundImage(index) {
      if (index < 6) {
        return require(`@/assets/img/${index + 1}.jpg`);
      } else {
        return require(`@/assets/img/${2 + 1}.jpg`);
      }
    },
    jump(item){
      let path=item.title.split(" ").join("-")
      this.$router.push({"path":path})
    }
  },
};
</script>
<style scoped lang="scss">
.prop {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid red;
  .carouselContainer {
    max-height: 100%;
    .heroContainer {
      height: 40vh;
      animation: font-weight 0.5s ease;
      .descContainer {
        //   max-width: 500px;
        font-size: 1.2rem;
        transition: background-color 0.8s ease, fong-weight 0.5s ease,
          color 0.5s ease;
        border-radius: 5px;
        margin: 0.5rem auto;
        padding: 0.2rem 1.2rem;
        height: 18vh;
        overflow-y: hidden;
        cursor:pointer;
        .desc {
          padding: 0.5rem 0;
          color: black;
          font-weight: 100;
          transition: font-weight 1s ease;
        }
      }
      .blogContent {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .titleContent {
          font-size: 2.5rem;
          //   margin-bottom: .5rem;
          color: #fff;
          font-weight: 300;
          // background-clip: text;
        }
      }
    }
    .heroContainer:hover {
      background: #26242480;
      border: 1px saddlebrown;
      font-weight: 500;
      color: transparent;
      background-clip: text;
      // font-size:1.25rem;
    }
    .heroContainer:hover .desc {
      font-weight: 200;
      //   color: transparent;
    }
  }
}

/* 手机设备（max-width: 767px） */
@media only screen and (max-width: 767px) {
  .prop {
    
    .carouselContainer {
    
      .heroContainer {
        box-sizing: border-box;
       padding:.3rem;
        .descContainer {
            padding:.2rem 1rem;
         font-size:.8rem;
 
          .desc {
           padding:0.2rem 0;
          
          }
        }
        .blogContent {
          
          .titleContent {
            font-size:1.2rem;
            
          }
        }
      }
      .heroContainer:hover {
       
      }
      .heroContainer:hover .desc {
       
      }
    }
  }
}
/* 平板设备（min-width: 768px）到（max-width: 1024px） */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

/* 小型桌面（min-width: 1025px）到（max-width: 1200px） */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
}

/* 标准桌面（min-width: 1200px）到（max-width: 1440px） */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
}
</style>