import Vue from 'vue'
import App from './App.vue'
import router from "./router"

Vue.config.productionTip = false
import './assets/css/materialdesignicons.min.css'
import "./assets/css/all.css"

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
