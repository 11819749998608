<template>
    <div class="creation">
        <div class="leisureTime level">
            
            <div class="title level-left"> Greated by leisure trip</div>
            <div class="level-right next" > next</div>
        </div>
        <div class="carousel carouselContainer">
            <b-carousel-list
            v-model="values"
            :data="items"
            :arrow="arrow"
            :arrow-hover="arrowHover"
            :items-to-show="perList"
            :items-to-list="increment"
            :repeat="repeat"
            :has-drag="drag"
            :has-grayscale="gray"
            :has-opacity="opacity"  >
            <template #item="list"  >
                <div class="carouselItem card" >
                  
                <div class="card-image">
                    <figure class="image is-5by4">
                        <a @click="info(list.index)"><img :src="backgroundImage(list.index)"></a>
                    </figure>
                    <!-- <b-tag type="is-danger" rounded style="position: absolute; top: 0;"><b>50%</b></b-tag> -->
                </div>
                <div class="card-content" >
                    <div class="content">
                        <p class="title is-6">{{ list.title }}</p>
                        <p class="subtitle is-7">traveller </p>
                        <b-field grouped >
                            <p class="control" v-if="list.rating">
                                <b-rate :value="list.rating" show-score disabled/>
                            </p>
                            <p class="control" style="margin-left: auto">
                                <b-button size="is-small" type="is-danger" icon-left="heart" outlined />
                            </p>
                        </b-field>
                    </div>
                </div>
                </div>
            </template>

        </b-carousel-list>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      arrow: true,
      arrowHover: true,
      drag: true,
      gray: false,
      opacity: false,
      values: 1,
      perList: 4,
      increment: 1,
      repeat: false,
      items: [
        {
          alt: "hotel info 1",
          title: "hotel info 1",
          rating:"2",
          image: "https://picsummm.photos/id/0/1230/500",
          srcFallback: "https://picsum.photos/id/0/1230/500",
        },
        {
          title: "attraction info 2",
          ating:"2",
          image: "https://picsum.photos/id/1/1230/500",
        },
        {
          title: "country info 3",
          ating:"2",
          image: "https://picsum.photos/id/2/1230/500",
        },
        {
          title: "room info 4",
          ating:"2",
          image: "https://picsum.photos/id/3/1230/500",
        },
        {
          title: "experience info 5",
          ating:"2",
          image: "https://picsum.photos/id/4/1230/500",
        },
        {
          title: "traveller info 6",
          ating:"2",
          image: "https://picsum.photos/id/5/1230/500",
        },
        {
          title: "meseum info 7",
          ating:"2",
          image: "https://picsum.photos/id/6/1230/500",
        },
        {
          title: "adventure info 8",
          ating:"2",
          image: "https://picsum.photos/id/7/1230/500",
        },
      ],
    };
  },
  methods:{
    backgroundImage(index) {
      if (index % 2) {
        return require(`@/assets/img/${(index % 3) + 1}.jpg`);
      }else{
        return require(`@/assets/img/${(index % 5) + 1}.jpg`);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.creation {
  width: 100%;
  .leisureTime {
    margin-bottom: 0;
  }
  .carouselContainer {
    min-height: 30vh;
    .carouselItem {
      min-height: 30vh;
      margin:1rem;
    }
  }
}
</style>