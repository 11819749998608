<template>
  <div class="article">
    <section class="level">
      <div class="level-left">
        <div class="level-item">
          <b-input
            placeholder="搜索文章标题"
            type="search"
            icon="magnify"
            size="is-small"
          >
          </b-input>
        </div>
      </div>

      <div class="level-item">
        <b-input
          placeholder="搜索关键词"
          type="search"
          icon="magnify"
          size="is-small"
        >
        </b-input>
      </div>
      <div class="level-item">
        <div class="level-item">
          <b-field>
            <b-radio-button
              size="is-small"
              v-model="typeIdentity"
              v-for="(type, index) in statusList"
              :key="index"
              :native-value="type"
              type="is-success  is-outlined"
            >
              {{ type }}
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div class="level-item">
        <!-- <b-checkbox v-model="showAllColumns" @click="showAllColumns"
          >展开所有列</b-checkbox
        > -->
      </div>
      <div class="level-right">
        <b-field class="level-item">
          <b-button
            size="is-small"
            icon-left="plus"
            type="is-success"
            @click="addNew"
            >添加</b-button
          >
        </b-field>
      </div>
    </section>
    <b-table
      size="is-small"
      :stickyHeaders="stickyHeaders"
      :data="list"
      paginated
      per-page="100"
      hoverable
      :debounce-search="1000"
      :loading="isLoading"
      narrowed
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <span>{{ props.row[column.field] }}</span>
      </b-table-column>
      <b-table-column  v-for="(column, index) in sourceColumns"
      :key="index"  v-slot="props" label="来源">
        <span>{{ props.row[column.field].name }}</span>
        <b-tag type="is-link is-light" @click="openLink(props.row[column.field])">打开网址</b-tag>
      </b-table-column>
      <b-table-column
        v-for="(column, index) in columnsType"
        :key="index"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <section>
          <b-tooltip
            :label="key.desc||key.cnName"
            v-for="(key, index) in props.row[column.field]"
            :key="index"
            type="is-success is-dark"
            multilined
          >
            <b-tag type="is-link is-light" size="is-small">{{
              key.name || key.enName
            }}</b-tag>
          </b-tooltip>
        </section>
      </b-table-column>
      <!-- <b-table-column
        v-for="(column, index) in columnsInfo"
        :key="index"
        :label="column.title"
        :visible="column.visible"
        v-slot="props"
      >
        <section>
          {{ props.row }}
        </section>
      </b-table-column> -->
      <b-table-column label="状态" v-slot="props">
        {{
          props.row.status == 0
            ? "草稿"
            : props.row.status == 1
            ? "刚创建"
            : props.row.status == 2
            ? "待发表"
            : props.row.status == 4
            ? "已发表"
            : props.row.status == 5
            ? "已删除"
            : props.row.status
        }}
      </b-table-column>
      <b-table-column label="操作" v-slot="props">
        <div class="controlPanel">
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            @click="edit(props.row)"
            type="is-info"
          >
          </b-button>

          <b-button
            size="is-small"
            icon-left="publish"
            type="is-success"
            @click="publish(props.row)"
          >
          </b-button>

          <b-button
            size="is-small"
            icon-left="delete"
            @click="deleteArticle(props.row)"
            type="is-danger"
          >
          </b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="openDrawer"
      :on-cancel="closeSideBar"
    >
      <articleForm :parentData="dataToForm" v-if="openDrawer"></articleForm>
      <!-- <TypeComponent
        :parentData="dataToFom"
       
      ></TypeComponent> -->
    </b-sidebar>
  </div>
</template>
<script>
import articleForm from "./articleForm.vue";
import postData from "../../../common/http";
export default {
  data() {
    return {
      cityId:0,
      typeIdentity: "",
      openSideBar: false,
      fullwidth: true,
      stickyHeaders: true,
      showAllColumns: false,
      fullheight: true,
      right: true,
      isOverlay: true,
      openDrawer: false,
      statusList: [
        "draft",
        "created",
        "reviewed",
        "scheduled",
        "published",
        "deleted",
      ],
      listPath: "/article/ListByAdmin",
      deleteArticlePath: "/article/delete",
      publishArticlePath: "/article/publish",
      list: [],
      columns: [
        { title: "ID", field: "id" },
        { title: "文章名称", field: "headline" },
        
        {title:"到达国家",field:"destCity"}
      ],
      sourceColumns:[{ title: "来源", field: "source" },],
      columnsType: [
        { title: "文章类型", field: "articleTypeList" },
        { title: "旅游类型", field: "tripTypeList" },

        { title: "关键词", field: "keywordList" },

        { title: "标签列表", field: "tagList" },
        { title: "景点列表", field: "sceneList" },
      ],
      columnsInfo: [
        // { title: "城市列表", field: "cityList" },

        // { title: "状态", field: "status" },
        { title: "热门", field: "isHot" },
        { title: "交通方式", field: "transportType" },
        { title: "目的城市", field: "destCity" },
        { title: "出发城市", field: "startCity" },
        { title: "酒店信息", field: "hotelInfo" },

        { title: "停留时长", field: "duration" },
        { title: "目的地天气", field: "wearch" },
        { title: "旅游月份", field: "tripTime" },
        { title: "亮点总结", field: "highlights" },
      ],
      isLoading:true,
    };
  },
  methods: {
    openLink(item){
      window.open(item.url,"_blank")
    },
    addNew() {
      this.dataToForm = {
        headline: "",
        status: 0,
        source: {
          name: "",
          url: "",
          mediaType: "",
          mediaLink: "",
          author: "",
        },
        keywordList: [],
        articleTypeList:[],
        tagList: [],
        cityIdList: [],
        sceneList: [],
        tripTypeList: [],
        startCity: "",
        destCity: "",
        hotelInfo: "",
        transportType: "",
        duration: "",
        weather: "",
        highlights: "",
        tripTime: "",
        isHot: 0,
      };
      this.openDrawer = true;
      this.openSideBar = true;
    },
    async publish(item) {
      let data = await postData(this.publishArticlePath, {
        json: { id: item.id },
      });
      if (data == null) {
        this.$buefy.toast.open("success");
      }
    },
    async deleteArticle(item) {
      let data = await postData(this.deleteArticlePath, {
        json: {
          id: item.id,
        },
      });
      if (data == null) {
        this.$buefy.toast.open("success");
        this.getList();
      }
    },
    async getList() {
      this.isLoading=true;
      let data = await postData(this.listPath, {
        json: {
          cityId: this.cityId||0,
          pageNo: 1,
          pageSize: 100,
        },
      });
      if(data){
        this.list = data.records;
        this.isLoading=false;
      }
      
    },
    toggleColumns() {
      this.showAllColumns != this.showAllColumns;
    },
    edit(item) {
      for(var key in item){
        if(item[key] === null){
          item[key]=[]
        }
      }
      this.dataToForm = { ...item };

      this.openDrawer = true;
      this.openSideBar = true;
    },
    closeSideBar() {
      this.openSideBar = false;
      this.getList();
    },
  },
  created() {
   
    this.getList();
  },
  watch: {
    showAllColumns(o, n) {
      let longColumns = [];
    },
  },
  components: { articleForm },
};
</script>

<style lang="scss" scoped>
.article {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.sidebar-content {
  min-width: 500px !important;
}
.controlPanel {
  display: flex;
  justify-content: space-between;
}
</style>


