<template>
  <section class="config ">
    <b-tabs type="is-boxed"  @input="changeTab" v-model="activeTab">
      <b-tab-item
        v-for="(item, index) in tabs"
        :key="index"
        :value="item.path"
        striped
        :sticky-header="stickyHeaders"
      >
        <template #header>
          <span tag="router-link"
                @click="changeTab(item)">{{ item.name }}</span>
        </template>
        <template>
          <span class="activeContent"  v-if="activeTab==item.path">
            <!-- <component :is="item.component"></component> -->
            <!-- {{ item.name }} -->
               
            <router-view > </router-view>
          </span>
        </template>
      </b-tab-item>
    </b-tabs>

</section>
</template>

<script>
import tabs from '../../common/config/tabs'
// console.log(tabs,33333)
export default {
  data() {
    return {
      tabs:[],
      stickyHeaders:true,
      activeTab:""
    };
  },
  methods: {
    changeTab(item) {
       
        if(item.path){
            this.$router.push({
                path:item.path
            }, () => {
                // console.log(this.tabs,this.activeTab);
                this.$forceUpdate()
            },
        () => {})
        }
    },
  },
  created(){
    this.tabs=tabs;
  
    this.activeTab= this.$route.path;
    this.$forceUpdate()
  }
};
</script>


<style lang="sass" scoped>
.config{
    margin-top:5em;
    width:100%;
    
}
</style>