import Vue from "vue"
import VueRouter from 'vue-router'
import main from '../views/main'
import config from '../views/config/index.vue'
import ArticleList from '../views/blog/articleList'
import Blog from '../views/blog/index.vue'
import Country from '../views/blog/country.vue'
import City from '../views/blog/city.vue'
import Scene from '../views/blog/scene.vue'
import List from '../views/blog/list.vue'
import tabs from '../common/config/tabs'
// import Countries from '../views/countries/index'
import Login from '../views/user/login.vue'
import Hots from '../views/hots/index.vue'
import News from '../views/news/index.vue'
import Scenes from '../views/scenes/index.vue'
import Headline from '../views/headline/index.vue'

import AboutUs from "../views/blog/aboutus"
import ContactUs from "../views/blog/contactus"

Vue.use(VueRouter)

const routes = [
    {
        path: "/about-us",
        name: "about-us",
        component: AboutUs
    },
    {
        path: "/contact",
        name: "contactus",
        component: ContactUs
    },
    {
        path: "/hots",
        name: "hots",
        component: Hots
    },
    {
        path: "/news",
        name: "news",
        component: News
    },
    {
        path: "/",
        name: "index",
        component: Blog,
        children: [
            {
                path: "/",
                component: ArticleList,
                name: "ArticleList"
            }
        ]

    },
    {
        name: "hot-countries",
        path: "/hot-countries",
        component: List,

    },
    {
        name: "hot-cities",
        path: "/hot-cities",
        component: List,
    },
    {
        name: "scenes",
        path: "/scenes",
        component: Scenes,
    },
    {
        path: "/asia/countries",
        name: "asiaCountries",
        // component:Countries
    },
    {
        path: "/countries",
        name: "Countries",
        // component:Countries
    },
    {
        path: "/asia/:country",
        name: "country",
        component: Country,
        children: [

        ]
    },
    {
        path: "/country",
        name: "country",
        component: Country,
        children: [

        ]
    },
    {
        path: "/country/:country",
        name: "country",
        component: Country,
        children: [

        ]
    },

    {
        path: "/city/:city",
        name: "city",
        component: City,

    },
    {
        path: "/scene",
        name: "scene",
        component: Scene
    },

    {
        path: "/config",
        name: "config",
        component: config,
        children: tabs

    },
    {
        path: "/travel",
        component: Headline,
        // children: [
        //     {
        //         path: '/asia/:country/:city',
        //         // component: CityPage,
        //         props: true
        //     },
        //     {
        //         path: '/asia/:country/:city/:attraction',
        //         // component: AttractionPage,
        //         props: true
        //     }
        // ]

    }, 
    {
        path: "/flight",
        children: [

        ]
    },
    {
        path: "/flight",
        children: [

        ]
    },
    {
        name: "login",
        path: "/login",
        component: Login
    }, {
        name: "articleSection",
        path: "/:headline",
        component: Headline
    }, {
        path: "/asia-travel/:articleType/:headline",
    },{
        path:"/travel/:type/:country/:city",
        component:Headline
    }



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router