<template>
  <div class="country">this is {{ $route.params }}</div>
</template>
<script>
export default {
  created() {
    
  },
};
</script>
<style lang="sass" scoped>
</style>
